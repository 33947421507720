//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';

//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//CRYPTO-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//MAIL-APP
export const GET_MAIL_LIST = 'get_mail_list';
export const GET_FOLDER_LIST = 'get_folder_list';
export const GET_LABEL_LIST = 'get_label_list';
export const TOGGLE_MAIL_DRAWER = 'toggle_mail_drawer';
export const COMPOSE_MAIL = 'compose_mail';
export const GET_MAIL_DETAIL = 'get_mail_detail';
export const UPDATE_MAIL_FOLDER = 'update_mail_folders';
export const UPDATE_MAIL_LABEL = 'update_mail_label';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const UPDATED_MAIL_DETAIL = 'updated_mail_detail';
export const CHANGE_READ_STATUS = 'change_read_status';
export const GET_CONNECTION_LIST = 'get_connection_list';
export const NULLIFY_MAIL = 'nullify_mail';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//CONTACT_APP
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_CONTACT_FOLDER_LIST = 'GET_CONTACT_FOLDER_LIST';
export const GET_CONTACT_LABEL_LIST = 'GET_CONTACT_LABEL_LIST';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL';
export const UPDATE_CONTACT_STARRED_STATUS = 'UPDATE_CONTACT_STARRED_STATUS';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER';
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';

//SCRUMBOARD_APP
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_SCRUM_LABEL_LIST = 'GET_SCRUM_LABEL_LIST';
export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARD_DETAIL = 'GET_BOARD_DETAIL';
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST';
export const ADD_LIST_CARD = 'ADD_LIST_CARD';
export const EDIT_LIST_CARD = 'EDIT_LIST_CARD';
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD';
export const DELETE_BOARD = 'DELETE_BOARD';
export const ADD_NEW_BOARD = 'ADD_NEW_BOARD';
export const DELETE_LIST = 'DELETE_LIST';
export const EDIT_BOARD_DETAIL = 'EDIT_BOARD_DETAIL';
export const EDIT_BOARD_LIST = 'EDIT_BOARD_LIST';

//CHAT_APP
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_USER_MESSAGES = 'get_user_messages';
export const ADD_NEW_MESSAGE = 'add_new_message';
export const EDIT_MESSAGE = 'edit_message';
export const DELETE_MESSAGE = 'delete_message';
export const DELETE_USER_MESSAGES = 'delete_user_messages';
export const TOGGLE_CHAT_DRAWER = 'toggle_chat_drawer';
export const SELECT_USER = 'select_user';

//WALL_APP
export const GET_WALL_DATA = 'get_wall_data';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_NEW_POST = 'create_new_post';
export const UPDATE_POST = 'update_post';

//USER_LIST
export const GET_USER_LIST = 'GET_USER_LIST';

//ECOMMERCE_LIST
export const GET_ECOMMERCE_LIST = 'get_ecommerce_list';
export const SET_PRODUCT_VIEW_TYPE = 'set_product_view_type';
export const SET_FILTER_DATA = 'set_filter_data';
export const SET_PRODUCT_DATA = 'set_product_data';
export const GET_RECENT_ORDER = 'get_recent_order';
export const GET_CUSTOMERS = 'get_customers';
export const ADD_CART_ITEM = 'add_cart_item';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const UPDATE_CART_ITEM = 'update_cart_item';
export const SET_CART_ITEMS = 'set_cart_items';

//CK-EDITOR
export const GET_BALLOON_BLOCK_DATA = 'get_balloon_block_data';
export const UPDATE_BALLOON_BLOCK_DATA = 'update_balloon_block_data';
export const GET_BALLOON_DATA = 'get_balloon_data';
export const UPDATE_BALLOON_DATA = 'update_balloon_data';
export const GET_CLASSIC_DATA = 'get_classic_data';
export const UPDATE_CLASSIC_DATA = 'update_classic_data';
export const GET_INLINE_DATA = 'get_inline_data';
export const UPDATE_INLINE_DATA = 'update_inline_data';
export const GET_DOCUMENT_DATA = 'get_document_data';
export const UPDATE_DOCUMENT_DATA = 'update_document_data';
export const GET_CUSTOM_DATA = 'get_custom_data';
export const UPDATE_CUSTOM_DATA = 'update_custom_data';

//GALLERY
export const GET_GALLERY_PHOTO = 'get_gallery_photo';


//APP USER_LIST
export const GET_APP_USER_LIST = 'GET_APP_USER_LIST';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const EDIT_APP_USER = 'EDIT_APP_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_APP_EDIT_USER = 'GET_APP_EDIT_USER';

//APP FACTURE_LIST
export const GET_LIST_FACTURE = 'GET_LIST_FACTURE';
export const GET_EDIT_FACTURE = 'GET_EDIT_FACTURE';
export const GET_NUMERO_DOSSIER = 'GET_NUMERO_DOSSIER';
export const CREATE_NEW_FACTURE = 'CREATE_NEW_FACTURE';
export const FACTURE_AGENCE_LIST = 'FACTURE_AGENCE_LIST';
export const EDIT_FACTURE = 'EDIT_FACTURE';
export const DELETE_FACTURE = 'DELETE_FACTURE';

//APP EMAIL PARAMETRABLE
export const GET_LIST_EMAIL = 'GET_LIST_EMAIL';
export const DELETE_EMAIL = 'DELETE_EMAIL';
export const CREATE_NEW_EMAIL = 'CREATE_NEW_EMAIL';
export const GET_EDIT_EMAIL = 'GET_EDIT_EMAIL';
export const EDIT_EMAIL = 'EDIT_EMAIL';

//APP NOTIFICATION	
export const GET_LIST_NOTIFICATION = 'GET_LIST_NOTIFICATION';
export const UPDATE_STATUT_NOTIFICATION = 'UPDATE_STATUT_NOTIFICATION';
export const RELANCE_NOTIFICATION = 'RELANCE_NOTIFICATION';
    

//APP MISSION_LIST
export const GET_APP_MISSION_LIST = 'GET_APP_MISSION_LIST';
export const MISSION_DELETE = 'MISSION_DELETE';
export const CREATE_NEW_MISSION = 'CREATE_NEW_MISSION';
export const EDIT_APP_MISSION = 'EDIT_APP_MISSION';
export const DELETE_MISSION = 'DELETE_MISSION';
export const GET_APP_EDIT_MISSION = 'GET_APP_EDIT_MISSION';
export const CHANGER_PASSWORD_USER = 'CHANGER_PASSWORD_USER';
export const EDIT_PROFILE_USER = 'EDIT_PROFILE_USER';

export const GET_ANNEXE_CADRES_LIST = 'GET_ANNEXE_CADRES_LIST';

export const MISSION_CREATION_REQUEST = 'MISSION_CREATION_REQUEST';
export const MISSION_CREATION_SUCCESS = 'MISSION_CREATION_SUCCESS';
export const MISSION_CREATION_FAIL = 'MISSION_CREATION_FAIL';
export const MISSION_CREATION_RESET = 'MISSION_CREATION_RESET';

export const GET_DOSSIER_LIST = 'GET_DOSSIER_LIST';
export const DOSSIER_CREATION_REQUEST = 'DOSSIER_CREATION_REQUEST';
export const DOSSIER_CREATION_SUCCESS = 'DOSSIER_CREATION_SUCCESS';
export const DOSSIER_CREATION_FAIL = 'DOSSIER_CREATION_FAIL';
export const DOSSIER_CREATION_RESET = 'DOSSIER_CREATION_RESET';

export const DOSSIER_UPDATE_REQUEST = 'DOSSIER_UPDATE_REQUEST';
export const DOSSIER_UPDATE_SUCCESS = 'DOSSIER_UPDATE_SUCCESS';
export const DOSSIER_UPDATE_FAIL = 'DOSSIER_UPDATE_FAIL';
export const DOSSIER_UPDATE_RESET = 'DOSSIER_UPDATE_RESET';
export const DOSSIER_DETAILS_REQUEST = 'DOSSIER_DETAILS_REQUEST';
export const DOSSIER_DETAILS_SUCCESS = 'DOSSIER_DETAILS_SUCCESS';
export const DOSSIER_DETAILS_FAIL = 'DOSSIER_DETAILS_FAIL';
export const DOSSIER_DETAILS_RESET = 'DOSSIER_DETAILS_RESET';
export const DELETE_DOSSIER = 'DELETE_DOSSIER';
export const FILTER_DOSSIER = 'FILTER_DOSSIER';
export const DOSSIER_DELETE_REQUEST = 'DOSSIER_DELETE_REQUEST';
export const DOSSIER_DELETE_SUCCESS = 'DOSSIER_DELETE_SUCCESS';
export const DOSSIER_DELETE_FAIL = 'DOSSIER_DELETE_FAIL';
export const DOSSIER_DOWNLOAD_REQUEST = 'DOSSIER_DOWNLOAD_REQUEST';
export const DOSSIER_DOWNLOAD_SUCCESS = 'DOSSIER_DOWNLOAD_SUCCESS';
export const DOSSIER_DOWNLOAD_FAIL = 'DOSSIER_DOWNLOAD_FAIL';

export const MISSION_UPDATE_REQUEST = 'MISSION_UPDATE_REQUEST';
export const MISSION_UPDATE_SUCCESS = 'MISSION_UPDATE_SUCCESS';
export const MISSION_UPDATE_FAIL = 'MISSION_UPDATE_FAIL';
export const UPDATE_UPDATE_FAIL = 'UPDATE_UPDATE_FAIL';
export const MISSION_DETAILS_RESET = 'MISSION_DETAILS_RESET';
export const MISSION_DETAILS_REQUEST = 'MISSION_DETAILS_REQUEST';
export const MISSION_DETAILS_SUCCESS = 'MISSION_DETAILS_SUCCESS';
export const MISSION_DETAILS_FAIL = 'MISSION_DETAILS_FAIL';
export const MISSION_UPDATE_RESET = 'MISSION_UPDATE_RESET';

export const GET_LIST_IMPORT = 'GET_LIST_IMPORT';
export const IMPORT_AGENCE_LIST = 'IMPORT_AGENCE_LIST';
export const CREATE_NEW_IMPORT = 'CREATE_NEW_IMPORT';