import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppEnums';

export const agencesConfigs = [
  {
    permittedRole:'admin',
    path: '/dashboards/agences',
    component: React.lazy(() => import('./agenceList/index')),
  },
  {
    permittedRole:'admin',
    path: '/create/agence',
    component: React.lazy(() => import('./createAgence/index')),
  },
  {
    permittedRole:'admin',
    path: '/edit/agence/:id',
    component: React.lazy(() => import('./editAgence/index')),
  },
];


