import React, {useEffect,useState} from 'react';
import AppSidebar from './AppSidebar';
import AppHeader from './AppHeader';
import { useHistory } from "react-router-dom";
import styles from './index.module.scss';
import AppContentView from '@crema/core/AppContentView';
import AppThemeSetting from '../../AppThemeSetting';
import AppFooter from '../components/AppFooter';
import AppScrollbar from '../../AppScrollbar';
import clsx from 'clsx';
import {useDispatch,useSelector} from 'react-redux';
import {FooterType, LayoutType} from '../../../../shared/constants/AppEnums';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';
import {onGetNotifications} from '../../../../redux/actions/notifications';


const Default = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {footer, layoutType, footerType} = useLayoutContext();

//   useEffect(() => {

//     dispatch(onGetNotifications());

//     history.listen((location) => {
//        dispatch(onGetNotifications());
//     });

//  }, [history]);
  
  return (
    <div
      className={clsx(
        styles.appLayout,
        footer && footerType === FooterType.FIXED ? styles.appMainFixedFooter : '',
        layoutType === LayoutType.BOXED ? styles.boxedLayout : '',
        layoutType === LayoutType.FRAMED ? styles.framedLayout : '',
        )}>
      <div className={styles.appDefaultLayout}>
        <AppSidebar />
        <div className={styles.appLayoutMain}>
          <AppHeader />
          <AppScrollbar className={styles.mainContentScroll}>
            <AppContentView />
            <AppFooter className={styles.appFooter} />
          </AppScrollbar>
        </div>
        {/* <AppThemeSetting /> */}
      </div>
    </div>
  );
};

export default React.memo(Default);
