import React from 'react';

import PropTypes from 'prop-types';
import styles from './NotificationItem.module.scss';
import { useHistory } from "react-router-dom";
import {Link} from 'react-router-dom';
import {Image, ListGroup} from 'react-bootstrap';
import {useDispatch,useSelector} from 'react-redux';
import {onGetNotifications,onUpdateNotifications} from '../../../redux/actions/notifications';
import clsx from 'clsx';

const NotificationItem = ({item,stateModal}) => {

  const dispatch = useDispatch();
  const history = useHistory();
  
  const redirectToFacture = () => {
     stateModal(false);
     dispatch(onUpdateNotifications());
     //dispatch(onUpdateNotifications(item.id));
     history.replace(`/show/facture/`+item.factureId);
  }


  return (
    <div onClick={redirectToFacture} className={styles.notifyLinkItem}>
        <ListGroup.Item
          className={clsx(
            styles.notifyListItem,
            `d-flex justify-content-start align-items-center border-0`,
          )}>
          {/* <Image
            className={styles.notifyMessageAvatar}
            src={item.image}
            alt={item.name}
            roundedCircle
          /> */}
          <div className='col-12 d-flex w-100'>
         
            
            <div className='col-9 d-flex'>
              <Image src='/assets/images/icon-pdf.png' width='37' height='35' />
              <div className={clsx(styles.notifyMessageContent, 'flex-grow-1')}>
                  <h3>{item.title}</h3>
                  <p>{item.sous_title}</p>
              </div>
            </div>
            
            <div className='col-3 d-flex justify-content-end'>
            {item.statut == true ? <span className={styles.NotificationLu}>Lu</span>:<span className={styles.NotificationNonLu}>Non lu</span>}
            </div>
          </div>


        </ListGroup.Item>
    </div>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};
