import React from 'react';

export const gestionDossiers = [
  {
    path: '/gestion-dossiers',
    component: React.lazy(() => import('./liste')),
  },
  {
    path: '/gestion-dossiers/ajout',
    component: React.lazy(() => import('./ajout')),
  },
  {
    path: '/gestion-dossiers/edition/:id',
    component: React.lazy(() => import('./ajout')),
  },
];
