import { GET_LIST_NOTIFICATION,UPDATE_STATUT_NOTIFICATION,RELANCE_NOTIFICATION} from '../../shared/constants/ActionTypes';

const initialState = {
    notifications_list: null,
    error:null,
};

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_NOTIFICATION:
            return {
                ...state,
                notifications_list: action.payload,
                error:null,
            };
        case UPDATE_STATUT_NOTIFICATION:
            return {
                ...state,
                notifications_list: action.payload,
                error:null,
            };
        case RELANCE_NOTIFICATION:
            return state;
        default:
            return state;
    }
};


export default notificationsReducer;
