import {
    FETCH_START,
    FETCH_SUCCESS,
    GET_DOSSIER_LIST,
    FETCH_ERROR,
    DOSSIER_CREATION_REQUEST,
    DOSSIER_CREATION_SUCCESS,
    DOSSIER_CREATION_FAIL,
    DOSSIER_CREATION_RESET,
    DOSSIER_UPDATE_REQUEST,
    DOSSIER_UPDATE_SUCCESS,
    DOSSIER_UPDATE_FAIL,
    DOSSIER_UPDATE_RESET,
    DOSSIER_DETAILS_REQUEST,
    DOSSIER_DETAILS_SUCCESS,
    DOSSIER_DETAILS_FAIL,
    DOSSIER_DETAILS_RESET,
    DOSSIER_DELETE_REQUEST,
    DOSSIER_DELETE_SUCCESS,
    DOSSIER_DELETE_FAIL,
    DELETE_DOSSIER,
    FILTER_DOSSIER
} from '../../shared/constants/ActionTypes';

const initialState = {
    dossierappList: [],
    dossierfiltrer: [],
};

export const dossierListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DOSSIER_LIST:
            return {
              ...state,
             dossierappList: action.payload,
             dossierfiltrer: action.payload,
            };
        case FILTER_DOSSIER:
            return {
                ...state,
                dossierfiltrer: action.payload,
            };
        default:
            return state;
    }
};

export const dossierCreationReducer = (state = {}, action) => {
    switch (action.type) {
        case DOSSIER_CREATION_REQUEST:
            return { loading: true }
        case DOSSIER_CREATION_SUCCESS:
            return { loading: false, success: true }
        case DOSSIER_CREATION_FAIL:
            return { loading: false, error: action.payload }
        case DOSSIER_CREATION_RESET:
            return {}
        default:
            return state
        }
}

export const dossierUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case DOSSIER_UPDATE_REQUEST:
            return { loading: true }
        case DOSSIER_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case DOSSIER_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case DOSSIER_UPDATE_RESET:
            return {}
        default:
            return state
        }
}

export const dossierDetailsReducer = (state = { dossier: null }, action) => {
    switch (action.type) {
        case DOSSIER_DETAILS_REQUEST:
            return { ...state, loading: true }
        case DOSSIER_DETAILS_SUCCESS:
            return { loading: false, dossier: action.payload }
        case DOSSIER_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        case DOSSIER_DETAILS_RESET:
            return { dossier: null }
        default:
            return state
    }
}

export const dossierDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case DOSSIER_DELETE_REQUEST:
            return { loading: true }
        case DOSSIER_DELETE_SUCCESS:
            return { loading: false, success: true }
        case DOSSIER_DELETE_FAIL:
            return { loading: false, success: false , error: action.payload }
        default:
            return state
    }
}