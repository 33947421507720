import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLayoutContext} from '../AppContextProvider/LayoutContextProvider';
// import {ThemeProvider} from 'react-bootstrap';
import { ThemeProvider } from "@mui/material";
import {ThemeDirection} from '../../../shared/constants/AppEnums';
import { frFR  } from '@mui/x-data-grid';
import { createTheme } from "@mui/material/styles";
import { CalendarPicker, LocalizationProvider, frFR as FrFrDateTimepicker } from '@mui/x-date-pickers';


const AppThemeProvider = (props) => {
  
const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '14px',
            fontWeight: 500,
            color: '#000',
            border: '1px solid #efefef',
            letterSpacing: '0px'
          },
          main:{
            background: '#ffffff',
          },
          columnHeaderTitle: {
            color: "#113c65",
            fontSize: 14,
            fontWeight: 'bold'
          },
          columnHeaders: {
            backgroundColor: "#e9edf1",
            borderBottom: '1px solid #efefef',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
          },
          cell: {
            borderBottom: '1px solid #efefef',
            padding: '0px 40px',
          },
          columnHeader: {
            // backgroundColor: "green"
            padding: '0px 40px',
          },
          columnSeparator:{
            display: 'none'
          }
        }
      }
    }
  },
  frFR,
  FrFrDateTimepicker
  );

  const {direction, } = useLayoutContext();
  /*  const {themeMode, } = useThemeContext();
    useEffect(() => {
      // Accessing scss variable "--background-color"
      // and "--text-color" using plain JavaScript
      // and changing the same according to the state of "darkTheme"
      const root = document.documentElement;
      root?.style.setProperty(
        "--bs-body-bg",
        themeMode===ThemeMode.DARK ? "#262833" : "#fff"
      );
      root?.style.setProperty("--bs-body-color", themeMode===ThemeMode.DARK ? "#fff" : "#262833");
    }, [themeMode]);*/

  useEffect(() => {
    document.body.setAttribute('dir', direction);
    document.body.setAttribute('lang', direction === ThemeDirection.LTR ? 'en' : 'ar');
  }, [direction]);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default React.memo(AppThemeProvider);

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
