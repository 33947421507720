import {GET_LIST_IMPORT,
    IMPORT_AGENCE_LIST,
    CREATE_NEW_IMPORT
} from '../../shared/constants/ActionTypes';

const initialState = {
    importList: [],
    agences: [],
    error:null,
    success: null
};

const importReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_LIST_IMPORT:
            return {
                ...state,
                importList: action.payload,
                error:null,
                success: null,
            };
        case IMPORT_AGENCE_LIST:
            return {
                ...state,
                agences: action.payload,
                error:null,
                loading: false,
                success: null,
            };
        case CREATE_NEW_IMPORT:
            return {
                ...state,
                importList: [...state.importList, action.payload],
                success: true,
                error:false,
                loading: false
            };
        default:
            return state;
    }
};
export default importReducer;