import React, {useEffect,useState} from 'react';
import styles from './index.module.scss';
import AppLogo from '../components/AppLogo';
import { useHistory } from "react-router-dom";
import AppLanguageSwitcher from '../../AppLanguageSwitcher';
import AppHeaderMessages from '../../AppHeaderMessages';
import AppNotifications from '../../AppNotifications';
import {FiMoreVertical} from 'react-icons/fi';
import {AiOutlineMenu} from 'react-icons/ai';
import {Dropdown} from 'react-bootstrap';
import clsx from 'clsx';
import AppSearch from '../../AppSearchBar';
import {useDispatch,useSelector} from 'react-redux';
import {toggleNavCollapsed} from '../../../../redux/actions';
import AppIconBtn from "../../AppIconBtn";
import {useAuthUser} from '@crema/utility/AuthHooks';
import UserInfo from '../components/UserInfo';
import {onGetNotifications} from '../../../../redux/actions/notifications';

const AppHeader = () => {

  const {user} = useAuthUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const {notifications_list} = useSelector(({notifications}) => notifications);
  const [headerMessageShow, setHeaderMessageShow] = useState(false);
  
  useEffect(() => {
      dispatch(onGetNotifications());

      history.listen((location) => {
         dispatch(onGetNotifications());
      });

   }, [history]);

  return (
    <div
      className={clsx(
        styles.appHeader,
        'd-flex align-items-center',
      )}>
      <a
        className={clsx(styles.trigger, 'd-xl-none me-3')}
        onClick={() => dispatch(toggleNavCollapsed())}>
        <AiOutlineMenu />
      </a>
      <div className='me-3 d-flex h-100'>
        {/* <AppLogo /> */}
        {/* <AppSearch /> */}
      </div>
      <div className='flex-grow-1' />
      {/* <div className={styles.appSearchWrapper}>
        <AppSearch />
      </div> */}
      <div className='d-none d-md-flex mx-n2'>
        {/* <span className='px-2'>
          <AppLanguageSwitcher />
        </span> */}
        
        {user.roles == 'agence' && <span className='px-2 notificationHeaderContainer d-flex justify-content-center align-items-center'>
          <AppNotifications dataNotifications={notifications_list} setHeaderMessageShow={setHeaderMessageShow} headerMessageShow={headerMessageShow}/>
        </span>}
        {/* <span className='px-2'>
          <AppHeaderMessages />
        </span> */}
        <span className='px-2'>
          <UserInfo hasColor setHeaderMessageShow={setHeaderMessageShow} headerMessageShow={headerMessageShow}/>
        </span>
      </div>
      {/* <div className='d-md-none'>
        <Dropdown>
          <Dropdown.Toggle
            id='dropdown-custom-components'
            className={clsx(
              styles.dropdownToggleBtn,
              'p-0 bg-transparent border-0 d-flex align-items-center w-100 shadow-none',
            )}>
            <AppIconBtn>
              <FiMoreVertical />
            </AppIconBtn>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">
              <AppHeaderMessages />
            </Dropdown.Item>
            <Dropdown.Item href="#/action-2">
              <AppNotifications />
            </Dropdown.Item>
            <Dropdown.Item href="#/action-3">
              <AppLanguageSwitcher />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
    </div>
  );
};

export default AppHeader;
