import {GET_APP_USER_LIST,
        CREATE_NEW_USER,
        DELETE_USER,
        GET_APP_EDIT_USER,
        EDIT_APP_USER,
        FETCH_ERROR,
        CHANGER_PASSWORD_USER,
        EDIT_PROFILE_USER
} from '../../shared/constants/ActionTypes';

const initialState = {
  usersappList: [],
  user_edit: null,
  error:null,
};

const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APP_USER_LIST:
      return {
        ...state,
        usersappList: action.payload,
        user_edit: null,
        error:null,
      };
    case CREATE_NEW_USER:
      return {
        ...state,
        usersappList: [...state.usersappList, action.payload],
        error:false,
      };
      //return [...state.usersappList, action.payload];
    case EDIT_APP_USER:
      return {
        ...state,
        error:false,
      };
    case DELETE_USER: 
      return {...state, 
              usersappList: state.usersappList.filter(({ id }) => id !== action.payload.userId),
              user_edit:null,
              error:null,
             };
    case GET_APP_EDIT_USER:
      return {...state, 
              user_edit:action.payload,
              error:null,
             };
    case CHANGER_PASSWORD_USER:
      return {...state};
    case EDIT_PROFILE_USER:
      return {...state};
    case FETCH_ERROR:
      return {...state, error:true };

    default:
      return state;
  }
};
export default userListReducer;
