import React from 'react';

export const emailParametrableConfigs = [
  {
    permittedRole:'admin',
    path: '/emails-configurable',
    component: React.lazy(() => import('./emailParametrableList/index')),
  },
  {
    permittedRole:'admin',
    path: '/create/email-configurable',
    component: React.lazy(() => import('./createOrEditEmail/index')),
  },
  {
    permittedRole:'admin',
    path: '/edit/email-configurable/:id',
    component: React.lazy(() => import('./createOrEditEmail/index')),
  },
];
