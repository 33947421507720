import React from 'react';

export const gestionOrdersMissionConfig = [
  {
    path: '/gestion-ordres-mission/demande',
    component: React.lazy(() => import('./demande')),
  },
  {
    path: '/gestion-ordres-mission/demande/:id',
    component: React.lazy(() => import('./demande')),
  },
  {
    path: '/gestion-ordres-mission/nos-ordres-mission',
    component: React.lazy(() => import('./nosOrdresMission')),
  },
];
