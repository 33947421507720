import {FaRegCalendarAlt, FaRegHospital} from 'react-icons/fa';
import {FiMail, FiMap, FiUsers} from 'react-icons/fi';
import {HiOutlineAcademicCap, HiOutlineChartSquareBar} from 'react-icons/hi';
import {
  RiBarChart2Line,
  RiCustomerService2Line,
  RiDashboardLine,
  RiShieldUserLine,
  RiTodoLine,
} from 'react-icons/ri';
import {BiCartAlt, BiDollar, BiErrorAlt, BiBookReader} from 'react-icons/bi';
import {CgComponents} from 'react-icons/cg';
import {
  BsBriefcase,
  BsCart4,
  BsChatDots,
  BsCurrencyBitcoin,
  BsQuestionDiamond,
} from 'react-icons/bs';
import {DiHtml5Multimedia} from 'react-icons/di';
import {
  MdInvertColors,
  MdOutlineAnalytics,
  MdOutlineContactPhone,
  MdOutlineContactSupport,
  MdOutlineDns,
  MdOutlineManageAccounts,
  MdTimeline,
} from 'react-icons/md';
import {CgAttachment, CgFeed} from 'react-icons/cg';
import {AiOutlineUnorderedList} from 'react-icons/ai';
import React from 'react';

const routesConfig = [
  {
    id: 'gestion_ordres_mission',
    title: 'Gestion ordres mission',
    messageId: 'sidebar.gestion_ordres_mission',
    type: 'collapse',
    children: [
      {
        id: 'gestion_ordres_mission_demande',
        title: 'Demande',
        messageId: 'sidebar.gestion_ordres_mission.demande',
        type: 'item',
        url: '/gestion-ordres-mission/demande',
      },
      {
        id: 'gestion_ordres_mission_ordres',
        title: 'Ordres',
        messageId: 'sidebar.gestion_ordres_mission.ordres',
        type: 'item',
        url: '/gestion-ordres-mission/nos-ordres-mission',
      }
    ]
  },
  {
    id: 'admin_gestion_ordres_mission',
    title: 'Les ordres mission',
    messageId: 'sidebar.admin_gestion_ordres_mission',
    type: 'item',
    url: '/gestion-ordres-mission/nos-ordres-mission',
  },
  {
    id: 'gestion_dossiers',
    title: 'Gestion Dossiers',
    messageId: 'sidebar.gestion_dossiers',
    type: 'item',
    url: '/gestion-dossiers',
  },
  {
    id: 'gestion_factures',
    title: 'Gestion Factures',
    messageId: 'sidebar.gestion_factures',
    type: 'item',
    url: '/factures',
  },
  // {
  //   id: 'gestion_donneurs_ordre',
  //   title: 'Gestion Donneurs Ordre',
  //   messageId: 'sidebar.gestion_donneurs_ordre',
  //   type: 'item',
  //   url: '/gestion-donneurs-ordre',
  // },
  {
    id: 'gestion_users',
    title: 'Gestion Users',
    messageId: 'sidebar.gestion_users',
    type: 'item',
    url: '/dashboards/users',
  },
  {
    id: 'gestion_agences',
    title: 'Gestion Agences',
    messageId: 'sidebar.gestion_agences',
    type: 'item',
    url: '/dashboards/agences',
  },
  {
    id: 'gestion_import',
    title: 'Gestion Import',
    messageId: 'sidebar.gestion_import',
    type: 'collapse',
    children: [
      {
        id: 'gestion_import_ajout',
        title: 'Importer',
        messageId: 'sidebar.gestion_import.ajout',
        type: 'item',
        url: '/import/ajout',
      },
      {
        id: 'gestion_ordres_mission_ordres',
        title: 'Liste imports',
        messageId: 'sidebar.gestion_import.liste',
        type: 'item',
        url: '/import/liste',
      }
    ]
  },
  {
    id: 'email_configurable',
    title: 'Emails paramétrables',
    messageId: 'sidebar.email_configurable',
    type: 'item',
    url: '/emails-configurable',
  }
];
export default routesConfig;
