import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import {useAuthMethod, useAuthUser} from '@crema/utility/AuthHooks';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import {getLogoAgence} from '../../../../../utils/Function';
import clsx from 'clsx';

const AppLogo = ({hasSidebarColor}) => {
  const {sidebarColorSet} = useSidebarContext();
  const {user} = useAuthUser();


  return (
    <div className={clsx(styles.appLogo, 'app-logo')}>
      {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
        <>
          <img className='d-none d-sm-block' src='/assets/images/logo-white-with-name.png' alt='crema-logo'/>
          <img className='d-sm-none' src='/assets/images/logo-white.png' alt='crema-logo'/>
        </>
      ) : (
        <>
          {user && user.roles == 'agence' && user.photoURL != null?(<img src={getLogoAgence(user.photoURL)} alt='crema-logo'/>):
             (<img src='/assets/images/logo-dlt-expertise.png' alt='crema-logo'/>)
             }
        </>
      )}
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
