import {GET_ANNEXE_CADRES_LIST} from '../../shared/constants/ActionTypes';

const initialState = {
  cadres: [],
};

const annexeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANNEXE_CADRES_LIST:
      return {
        ...state,
        cadres: action.payload,
      };

    default:
      return state;
  }
};
export default annexeReducer;
