import React from 'react';

import NavVerticalGroup from './VerticalNavGroup';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import routesConfig from '../../../../../pages/routeConfig';
import {useAuthMethod, useAuthUser} from '../../../../../@crema/utility/AuthHooks';
import {removeObjectWithId } from '../../../../../utils/Function';

const VerticalNav = () => {

  const {user} = useAuthUser();
  var routes = [];
  if(user.roles == "agence"){
    routes = removeObjectWithId(routesConfig,'gestion_users');
    routes = removeObjectWithId(routes,'gestion_agences');
    routes = removeObjectWithId(routes,'gestion_import');
    routes = removeObjectWithId(routes,'email_configurable');
    routes = removeObjectWithId(routes,'admin_gestion_ordres_mission');
  }else{
    routes = removeObjectWithId(routesConfig,'gestion_ordres_mission');
  }

  return (
    <div className='position-relative pt-2 sidebar-routesconfig'>
      {routes.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && <NavVerticalGroup item={item} level={0} />}

          {item.type === 'collapse' && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === 'item' && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default VerticalNav;
