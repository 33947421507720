import {GET_LIST_EMAIL,DELETE_EMAIL,CREATE_NEW_EMAIL,GET_EDIT_EMAIL,EDIT_EMAIL} from '../../shared/constants/ActionTypes';

const initialState = {
    emailList: [],
    emailEdit: null,
    error:null,
};

const factureReducer = (state = initialState, action) => {
switch (action.type) {
    case GET_LIST_EMAIL:
        return {
            ...state,
            emailList: action.payload,
            emailEdit: null,
            error:null,
        };
    case CREATE_NEW_EMAIL:
        return {
            ...state,
            emailList: [...state.emailList, action.payload],
            emailEdit: null,
            error:false,
        };
    case GET_EDIT_EMAIL:
        return {...state, 
                 emailEdit:action.payload,
                 error:null,
                };
    case EDIT_EMAIL:
        return {
            ...state,
            error:false,
        };
    case DELETE_EMAIL: 
        return {
            ...state, 
            emailList : state.emailList.filter(({ id }) => id !== action.payload.id),
            emailEdit: null,
            error:null,
        };
    default:
        return state;
}
};

export default factureReducer;
