import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Dashboard from './Dashboard';
import ToDoApp from './ToDoApp';
import MailApp from './MailApp';
import ContactApp from './ContactApp';
import ScrumboardApp from './ScrumboardApp';
import Ecommerce from './Ecommerce';
import ChatApp from './ChatApp';
import Wall from './Wall';
import UserList from './UserList';
import User from './User';
import notifications from './notifications';
import facture from './facture';
import emailConfigurable from './emailConfigurable';

import {missionListReducer, missionCreationReducer, missionUpdateReducer, missionDetailsReducer} from './Mission';
import Annexe from './Annexe';
import {dossierListReducer, dossierCreationReducer, dossierUpdateReducer, dossierDetailsReducer, dossierDeleteReducer} from './Dossier';
import importReducer from './Import';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    dashboard: Dashboard,
    common: Common,
    todoApp: ToDoApp,
    mailApp: MailApp,
    userList: UserList,
    contactApp: ContactApp,
    scrumboardApp: ScrumboardApp,
    ecommerce: Ecommerce,
    chatApp: ChatApp,
    wall: Wall,
    users: User,
    missions: missionListReducer,
    annexes: Annexe,
    factures: facture,
    emailConfigurable: emailConfigurable,
    notifications: notifications,
    missionCreate :missionCreationReducer,
    dossiers :dossierListReducer,
    dossierCreate :dossierCreationReducer,
    dossierUpdate :dossierUpdateReducer,
    dossierDetails: dossierDetailsReducer,
    dossierDelete: dossierDeleteReducer,
    missionCreate : missionCreationReducer,
    missionUpdate : missionUpdateReducer,
    missionDetails: missionDetailsReducer,
    importInformations: importReducer
  });
export default reducers;
