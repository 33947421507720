import React from 'react';

export const gestionImportConfig = [
  {
    path: '/import/liste',
    component: React.lazy(() => import('./liste')),
  },
  {
    path: '/import/ajout',
    component: React.lazy(() => import('./ajout')),
  },
];
