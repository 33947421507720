import React, {useState} from 'react';
import notification from '../../services/db/notifications/notification';
import { useHistory } from "react-router-dom";
import AppScrollbar from '../AppScrollbar';
import IntlMessages from '../../utility/IntlMessages';
import NotificationItem from './NotificationItem';
import styles from './index.module.scss';
import {IoIosNotificationsOutline} from 'react-icons/io';
import {Button, ListGroup, Offcanvas} from 'react-bootstrap';
import {useDispatch,useSelector} from 'react-redux';
import clsx from 'clsx';
import AppIconBtn from '../AppIconBtn';
import messages from '../../services/db/messages/messages';
import {onGetNotifications,onUpdateNotifications} from '../../../redux/actions/notifications';

const AppNotifications = ({dataNotifications,setHeaderMessageShow,headerMessageShow}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  // const [headerMessageShow, setHeaderMessageShow] = useState(false);

  // const onHeaderMessageClose = () =>{
  //    setHeaderMessageShow(false);
  // } 

  // const onHeaderMessageShow = () =>{
  //    setHeaderMessageShow(true);
  // } 

  const onCloseModal = () =>{
      dispatch(onUpdateNotifications());
      setHeaderMessageShow(false);
  }

  const onRedirectNotificationList = () =>{
      dispatch(onUpdateNotifications());
      setHeaderMessageShow(false);
      history.replace('/historique/notifications');
  }


  return (
    <>
      <span onClick={()=>setHeaderMessageShow(true)}>
        <AppIconBtn className='d-none d-md-flex iconNotificationBtn'>
          <div className={styles.Notifications}>
             {dataNotifications && dataNotifications.nb_notification != 0 && <div className={styles.NotificationNumber}>{dataNotifications.nb_notification}</div>}
             <IoIosNotificationsOutline />
          </div>
        </AppIconBtn>
        <span className={clsx(styles.notifyText, 'd-md-none')}>
          <IntlMessages id='common.notifications' />
        </span>
      </span>

      <Offcanvas className={styles.offcanvaView}
        show={headerMessageShow}
        onHide={onCloseModal}
        placement='end'>
        <Offcanvas.Header className={styles.offcanvasHeader} closeButton>
          <Offcanvas.Title>
            <IntlMessages id='dashboard.notifications' /> {/* ({messages.length}) */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='p-0'>
          <AppScrollbar className={styles.notifyScrollSubmenu}>
            <ListGroup className={clsx(styles.notifyList, 'border-0')}>
              {dataNotifications && dataNotifications.notifications.length > 0 ? (dataNotifications.notifications.slice(0, 10).map((item, index) => (
                <NotificationItem key={index} item={item} stateModal={setHeaderMessageShow} />
              ))):(<div className='text-center mt-5'>Aucune notification</div>)}
            </ListGroup>
          </AppScrollbar>
          <div
            className={clsx(
              styles.notifyFooterView,
              'd-flex flex-column justify-content-end',
            )}>
            <Button type='primary' className={styles.notifyBtnAll} onClick={onRedirectNotificationList}>
              <IntlMessages id='common.viewAll' />
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AppNotifications;
