import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    GET_LIST_NOTIFICATION,
    UPDATE_STATUT_NOTIFICATION,
    RELANCE_NOTIFICATION
} from '../../shared/constants/ActionTypes';
import {appIntl} from '@crema/utility/helper/Utils';
import jwtAxios from '@crema/services/auth/jwt-auth';
import fd_Axios from '@crema/services/auth/jwt-auth/format-data-api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
export const onGetNotifications = () => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    jwtAxios.get('/list/notifications').then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_LIST_NOTIFICATION, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};


export const onUpdateNotifications = () => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    jwtAxios.put('/update/statut/notifications/').then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_STATUT_NOTIFICATION, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};


export const onRelanceNotification = (factureId) => {
  return (dispatch) => {
    const {messages} = appIntl();
    dispatch({type: FETCH_START});
    jwtAxios.post('/notification/relance/'+factureId).then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: RELANCE_NOTIFICATION});
          toast.success('Email de rélance envoyée avec succès !', {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
          toast.error('Erreur lors de l\'envoi d\'un email !', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };
};

// export const onUpdateNotifications = (factureId) => {
//   return (dispatch) => {
//     const {messages} = appIntl();
//     dispatch({type: FETCH_START});
//     fd_Axios.put('/update/statut/notifications/'+factureId).then((data) => {
//         if (data.status === 200) {
//           dispatch({type: FETCH_SUCCESS});
//           dispatch({type: UPDATE_STATUT_NOTIFICATION, payload: data.data});
//         } else {
//           dispatch({
//             type: FETCH_ERROR,
//             payload: messages['message.somethingWentWrong'],
//           });
//         }
//       })
//       .catch((error) => {
//         dispatch({type: FETCH_ERROR, payload: error.message});
//       });
//   };
// };


  