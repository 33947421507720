import {GET_LIST_FACTURE,
        CREATE_NEW_FACTURE,
        EDIT_FACTURE,
        DELETE_FACTURE,
        FETCH_ERROR,
        FACTURE_AGENCE_LIST,
        GET_EDIT_FACTURE,
        GET_NUMERO_DOSSIER
} from '../../shared/constants/ActionTypes';

const initialState = {
    factureList: [],
    agenceList: [],
    NumeroDossier:[],
    factureEdit: null,
    error:null,
};

const factureReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_FACTURE:
            return {
                ...state,
                factureList: action.payload,
                factureEdit: null,
                NumeroDossier:[],
                error:null,
            };
        case FACTURE_AGENCE_LIST:
            return {
                ...state,
                agenceList: action.payload,
                NumeroDossier:[],
                error:null,
            };
        case CREATE_NEW_FACTURE:
            return {
                ...state,
                factureList: [...state.factureList, action.payload],
                error:false,
            };
        case EDIT_FACTURE:
            return {
                ...state,
                error:false,
            };
        case GET_NUMERO_DOSSIER:
            return {
                ...state,
                NumeroDossier: action.payload,
                error:null,
            };
        case DELETE_FACTURE: 
            return {...state, 
                    factureList: state.factureList.filter(({ id }) => id !== action.payload.id),
                    factureEdit:null,
                    NumeroDossier:[],
                    error:null,
                    };
        case GET_EDIT_FACTURE:
            return {...state, 
                    factureEdit:action.payload,
                    NumeroDossier:[],
                    error:null,
                    };
        // case CHANGER_PASSWORD_USER:
        //     return {...state};
        // case EDIT_PROFILE_USER:
        //     return {...state};
        // case FETCH_ERROR:
        //     return {...state, error:true };

        default:
            return state;
    }
};

export default factureReducer;
