import React from 'react';

export const usersConfigs = [
  {
    permittedRole:'admin',
    path: '/dashboards/users',
    component: React.lazy(() => import('./userList/index')),
  },
  {
    permittedRole:'admin',
    path: '/create/user',
    component: React.lazy(() => import('./createUser/index')),
  },
  {
    permittedRole:'admin',
    path: '/edit/user/:id',
    component: React.lazy(() => import('./editUser/index')),
  },
];
