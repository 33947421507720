import {
    GET_APP_MISSION_LIST,CREATE_NEW_MISSION,DELETE_MISSION,GET_APP_EDIT_MISSION,EDIT_APP_MISSION,
    MISSION_CREATION_REQUEST, MISSION_CREATION_SUCCESS, MISSION_CREATION_FAIL, MISSION_CREATION_RESET,
    MISSION_UPDATE_REQUEST, MISSION_UPDATE_SUCCESS, MISSION_UPDATE_FAIL, MISSION_UPDATE_RESET,
    MISSION_DETAILS_REQUEST, MISSION_DETAILS_SUCCESS, MISSION_DETAILS_FAIL, MISSION_DETAILS_RESET,MISSION_DELETE,
    FETCH_ERROR
} from '../../shared/constants/ActionTypes';

const initialState = {
    missionappList: []
};

export const missionListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_APP_MISSION_LIST:
            return {
              ...state,
              missionappList: action.payload
            };
        case MISSION_DELETE:
            return {
                ...state,
                missionappList: state.missionappList.filter(({ id }) => id !== action.payload.missionId)
            };
        default:
            return state;
    }
};

export const missionCreationReducer = (state = {}, action) => {
    switch (action.type) {
        case MISSION_CREATION_REQUEST:
            return { loading: true }
        case MISSION_CREATION_SUCCESS:
            return { loading: false, success: true }
        case MISSION_CREATION_FAIL:
            return { loading: false, error: action.payload }
        case MISSION_CREATION_RESET:
            return {}
        default:
            return state
        }
}

export const missionUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case MISSION_UPDATE_REQUEST:
            return { loading: true }
        case MISSION_UPDATE_SUCCESS:
            return { loading: false, success: true }
        case MISSION_UPDATE_FAIL:
            return { loading: false, error: action.payload }
        case MISSION_UPDATE_RESET:
            return {}
        default:
            return state
        }
}

export const missionDetailsReducer = (state = { mission: null }, action) => {
    switch (action.type) {
        case MISSION_DETAILS_REQUEST:
            return { ...state, loading: true }
        case MISSION_DETAILS_SUCCESS:
            return { loading: false, mission: action.payload }
        case MISSION_DETAILS_FAIL:
            return { loading: false, error: action.payload }
        case MISSION_DETAILS_RESET:
            return { mission: null }
        default:
            return state
    }
}