import React from 'react';

export const facturesConfigs = [
  {
    path: '/factures',
    component: React.lazy(() => import('./factureList/index')),
  },
  {
    permittedRole:'admin',
    path: '/create/facture',
    component: React.lazy(() => import('./createOrEditFacture/index')),
  },
  {
    permittedRole:'admin',
    path: '/edit/facture/:id',
    component: React.lazy(() => import('./createOrEditFacture/index')),
  },
  {
    path: '/show/facture/:id',
    component: React.lazy(() => import('./factureDetail/index')),
  },
];
